import { createStore } from 'vuex'
import { auth } from "./modules/auth.module";
import { layout } from "./modules/layout.module";
import { user } from "./modules/user.module";
import { files } from "./modules/files.module";

const store = createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    layout,
    user,
    files
  }
})

window.store = store;

export default store;
/* eslint-disable no-unused-vars */
import { getFirestore, collection, doc, query, where, getDocs } from "firebase/firestore";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, fetchSignInMethodsForEmail} from "firebase/auth"
import { getStorage } from "firebase/storage"
import axios from "axios";

let api = axios.create({
    baseURL: "https://app.thegood.link/api",
    headers: {
        "Content-type": "application/json"
    }
})

export const auth = {
    namespaced: true,
    state: {
        checking: true,
        loggedIn: false,
        user: null,
        fireAuth: null,
        fs: null,
        db: null,
        storage: null,
        after: { name: "edit" }
    },
    getters: {
        isLoggedIn: (state) => { return state.loggedIn; },
        isChecking: (state) => { return state.checking; },
        getFS: (state) => { return state.fs; },
        getDB: (state) => { return state.db; },
        getStorage: (state) => { return state.storage; },
        getAfter: (state) => { return state.after }
    },
    mutations: {
        SET_USER: (state, user) => {
            state.user = user;
        },
        SET_LOGGEDIN: (state, loggedIn) => {
            state.loggedIn = loggedIn;
        },
        SET_CHECKING: (state, checking) => {
            state.checking = checking;
        },
        SET_FIREAUTH: (state, a) => {
            state.fireAuth = a;
        },
        SET_FIRESTORE: (state, fs) => {
            state.fs = fs;
            state.db = {
                users: collection(fs, "users"),
                pages: collection(fs, "pages"),
                links: collection(fs, "links"),
                files: collection(fs, "files")
            }
            
        },
        SET_STORAGE: (state, s) => {
            state.storage = s;
        }
    },
    actions: {
        checkLoginState: function(context) {

            context.commit("SET_CHECKING", true);
            
            const a = getAuth();
            context.commit("SET_FIREAUTH", a);
            const fs = getFirestore();
            context.commit("SET_FIRESTORE", fs);
            const st = getStorage();
            context.commit("SET_STORAGE", st);
            context.dispatch("user/setup", { db: context.state.db }, { root: true });

            var unsubscribe = onAuthStateChanged( a, function(firebaseUser) {
                unsubscribe();

                context.commit("SET_CHECKING", false);

                if(firebaseUser !== null) {
                    var user = firebaseUser.toJSON();
                    console.log("user found", user);
                    context.commit("SET_LOGGEDIN", true);
                    context.dispatch("user/setup", { authUser: user }, { root: true });
                    context.commit("SET_USER", user);
                } else {
                    context.commit("SET_LOGGEDIN", false);
                    context.dispatch("user/clearUser", {}, { root: true });
                    console.log("no user found");
                }
            })
        },
        checkEmailActive: function(context, email) {
            return new Promise((resolve, reject) => {
                if(!email) return reject(new Error("Invalid email address"));

                console.log("checking for", email);

                fetchSignInMethodsForEmail(context.state.fireAuth, email)
                .then((result) => {
                    var active = false;
                    console.log({email, result});
                    if(result.length && result.includes("password")) active = true;
                    resolve(active);
                })

            })
        },
        doLogin: function(context, { email, password }) {
            return new Promise((resolve, reject) => {
                if(!email) return reject(new Error("Invalid email address"));
                if(!password) return reject(new Error("Need a password for this folks!"));

                signInWithEmailAndPassword(context.state.fireAuth, email, password)
                .then(userDetails => {
                    var user = userDetails.user.toJSON();
                    console.log({user});
                    context.commit("SET_LOGGEDIN", true);
                    context.commit("SET_USER", user);
                    context.dispatch("user/setup", { authUser: user}, { root: true });
                    resolve(user);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
            })
        },
        doLogout: function(context) {
            return new Promise((resolve, reject) => {

                signOut(context.state.fireAuth)
                .then(() => {
                    context.commit("SET_LOGGEDIN", false);
                    context.dispatch("user/clearUser", {}, { root: true });
                    resolve(null);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })    
            })            
        },
        signUp: function(context, { email, password, handle}) {
            return new Promise((resolve, reject) => {
                if(!email) return reject(new Error("Invalid email address"));
                if(!password) return reject(new Error("Need a password for this folks!"));

                api.post("/user/create", { email, password, handle })
                .then(function (response) {
                    // handle success
                    console.log("response received", response);
                    if(response.data && response.data.success) {
                        var data = response.data.data;
                        if(data.newUser) {
                            context.dispatch("doLogin", { email, password });
                            resolve(data.newUser);
                        }
                    } else if(response.data && response.data.error) {
                        reject(response.data.error);
                    }

                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                    reject(error);
                })


            })
        },
        checkHandle: function(context, handle) {
            return new Promise((resolve, reject) => {
                if(!handle) return reject(new Error("No handle provided!"));

                var q = query(context.state.db.pages, where("handle", "==", handle.toLowerCase()));

                getDocs(q).then(querySnapshot => {
                    switch(querySnapshot.size) {
                        case 0:
                            resolve({ handle: null});
                            break;
    
                        case 1:
                            resolve({ handle: querySnapshot.docs[0].data() });
                            break;
    
                        default:
                            var handles = [];
                            querySnapshot.forEach((doc) => {
                                handles.push(doc.data());
                            })
                            resolve({ err: new Error("too many results"), handles });
                            break;
                    }
                })
                .catch(err => {
                    console.error(err);
                    reject({ err });
                })
                

            })
        }
    }
}
<template>
  <div id="appInner" class="main-container cover-bg">
    <div class="background-image cover-image" :style="bg" :class="{'blurred': background.blurred }"></div>
    <div class="background-overlay" :style="overlayStyle"></div>
    <router-view/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters({
        currentBG: "layout/getCurrentBG",
        overlayStyle: "layout/getOverlayStyle",
        background: "layout/getBackground"
    }),
    bg: function() {
        var img = this.background.image || this.background.default;

        var style = {
          backgroundImage: `url(${img})`
        }

        return style;
    }
  },
  methods: {
    ...mapActions({
      checkLogin: "auth/checkLoginState",
      setOverlay: "layout/setOverlay"
    }),
    setup: function() {
      this.checkLogin();
      this.setOverlay({opacity: 0.3});
    }
  },
  mounted: function() {
    this.setup();
    window.gl = this;
  }
}
</script>


<style lang="scss">
#appInner {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;

  height: 100%;
  width: 100vw;
  min-height: 100vh;
  max-width: 100vw;

    .background-image {
      position: fixed;
      top: -50px;
      left: -50px;
      bottom: -50px;
      right: -50px;

      &.blurred {
        filter: blur(40px);
      }
    }
}

</style>

import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store/"

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    beforeEnter: (to, from, next) => {

      if(store.getters["auth/isLoggedIn"]) {
        //store.dispatch("layout/openNavbar", {root: true});
        next({ name: "admin" });
      } else {
        next({ name: "login" });
      }
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/Admin.vue'),
    children: [
      {
        path: 'stats',
        name: 'stats',
        component: () => import('../views/Admin.vue'),
      }
    ],
    beforeEnter: (to, from, next) => {

      if(store.getters["auth/isLoggedIn"]) {
        //store.dispatch("layout/openNavbar", {root: true});
        next();
      } else {
        next({ name: "login" });
      }
    }
  },
  {
    path: '/admin/user',
    name: 'user',
    component: () => import('../views/User.vue'),
    beforeEnter: (to, from, next) => {

      if(store.getters["auth/isLoggedIn"]) {
        //store.dispatch("layout/openNavbar", {root: true});
        next();
      } else {
        next({ name: "login" });
      }
    }
  },
  {
    path: '/admin/edit',
    name: 'edit',
    component: () => import('../views/Editor.vue'),
    children: [
      {
        path: 'preview/mobile',
        name: 'previewMobile',
        component: () => import('../views/Editor.vue'),
        meta: {
          preview: "mobile",
        }
      },
      {
        path: 'preview/desktop',
        name: 'previewDesktop',
        component: () => import('../views/Editor.vue'),
      }
    ],
    beforeEnter: (to, from, next) => {

      if(store.getters["auth/isLoggedIn"]) {
        //store.dispatch("layout/openNavbar", {root: true});
        next();
      } else {
        next({ name: "login" });
      }
    }
  },
  {
    path: '/join',
    name: 'signup',
    props: {
      signup: true
    },
    beforeEnter: (to, from, next) => {

      if(store.getters["auth/isLoggedIn"]) {
        next({ name: "admin" });
      } else {
        next();
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login.vue')
  },
  {
    path: '/login',
    name: 'login',
    props: {
      signup: false
    },
    beforeEnter: (to, from, next) => {
      if(store.getters["auth/isLoggedIn"]) {
        next({ name: "admin" });
      } else {
        next();
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

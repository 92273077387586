/* eslint-disable no-unused-vars */
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import VueTippy from "vue-tippy";
import 'tippy.js/dist/tippy.css' // optional for styling
// import "vue2-animate/dist/vue2-animate.min.css";
// import Toast from "vue-toastification";
// // Import the CSS or use your own!
// import "vue-toastification/dist/index.css";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte

// Create an instance of Notyf
const notyf = new Notyf();

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBnSuboRhjr_ZPnV0u5_IWEMoOGnBFTFvk",
  authDomain: "good-link.firebaseapp.com",
  projectId: "good-link",
  storageBucket: "good-link.appspot.com",
  messagingSenderId: "615266598471",
  appId: "1:615266598471:web:6813220c3a1704bd733b62",
  measurementId: "G-BKCT4F7FGW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


createApp(App)
  .use(VueTippy)
  .use(notyf)
  .use(store)
  .use(router)
  .mount('#app')

// import * as Vue from "vue";
import { each, set } from "lodash-es";

export const layout = {
    namespaced: true,
    state: {
        background: {
            default: require("@/assets/images/treeline-1.jpg"),
            blurred: false,
            image: null
        },
        logo: {
            blue: require("@/assets/images/goodlink-logo-blue.png"),
            white: require("@/assets/images/goodlink-logo-white.png"),
        },
        overlay: {
            color: "black",
            opacity: 0.7
        },
        upload: {
            ing: false,
            progress: 0
        }
    },
    getters: {
        getCurrentBG: (state) => { return state.background.image || state.background.default; },
        getLogo: (state) => { return state.logo; },
        getOverlayStyle: (state) => { return {
                backgroundColor: state.overlay.color,
                opacity: state.overlay.opacity
            }
        },
        getBackground: (state) => { return state.background; },
        getUpload: (state) => { return state.upload; }
    },
    mutations: {
        SET_OVERLAY: (state, props) => {
            each(props, (val, key) => {
                state.overlay[key] = val;
            })
        },
        SET_VALUE: function(state, props) {
            each(props, (val, key) => {
                console.log("setting", key);
                set(state, key, val);
            })
        },
    },
    actions: {
        setOverlay: function(context, props) {
            context.commit("SET_OVERLAY", props);
        },
        setBackground: function(context, props) {
            context.commit("SET_VALUE", props);
        },
        setUpload: function(context, props) {
            context.commit("SET_VALUE", props);
        }
    }
}
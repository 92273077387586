import { each } from "lodash-es";

export const files = {
    namespaced: true,
    state: {
        db: null
    },
    getters: {
        getDB: (state) => { return state.db; }
    },
    mutations: {
        SET_VALUE: function(state, props) {
            each(props, (val, key) => {
                console.log("setting", key);
                state[key] = val;
            })
        },
    },
    actions: {
        setup: function(context, props) {
            context.commit("SET_VALUE", props);
        }
    }
}
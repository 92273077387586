import { doc, getDoc, setDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { find, findIndex, each } from "lodash-es";
import { Notyf } from 'notyf';
const notyf = new Notyf({
    position: { x: 'center', y: 'top' },
    types: [
        {
            type: "success",
            className: "success-toast"
        }
    ]
});

//pexels APIkey 563492ad6f91700001000001efe5ddd1a82c47afb08e143b9830ce62

export const user = {
    namespaced: true,
    state: {
        authUser: null,
        user: null,
        userRef: null,
        db: null,
        gravatar: null,
        profilePic: null,
        pages: []
    },
    getters: {
        getUser: (state) => { return state.user; },
        getProfilePic: (state) => { return state.profilePic; },
        getPage: (state) => ({index, id, handle}) => {
            var page = index !== undefined && index > -1 ? state.pages[index] : find(state.pages, p => { return p.id === id || p.handle === handle; });
            return page;
        },
        getUserRef: (state) => { return state.userRef; }
    },
    mutations: {
        SET_VALUE: function(state, props) {
            each(props, (val, key) => {
                console.log("setting", key);
                state[key] = val;
            })
        },
        SET_PAGE: function(state, page) {
            var pageIndex = findIndex(state.pages, p => { return p.id === page.id; });

            if(pageIndex > -1) {
                state.pages[pageIndex] = page;
            } else {
                state.pages.push(page);
            }
        }
    },
    actions: {
        setup: function(context, params) {
            
            context.commit("SET_VALUE", params);

            if(params.authUser && !params.user) {
                var userRef = doc(context.state.db.users, params.authUser.uid);
                context.commit("SET_VALUE", { userRef });

                getDoc(userRef).then(snapshot => {
                    if(snapshot.exists()) {
                        var user = snapshot.data();
                        context.commit("SET_VALUE", { user });

                        var pages = user.pages;

                        each(pages, p => {
                            context.dispatch("loadPage", { ref: p.ref})
                        })
                    }
                })
                .catch(err => {
                    console.error(err);
                })
            }
        },
        clearUser: function(context) {
            context.commit("SET_VALUE", { user: null });
            context.commit("SET_VALUE", { pages: [] });
            context.dispatch("layout/setBackground", { image: null, blurred: false}, { root: true})
        },
        loadPage: function(context, { ref, id }) {
            return new Promise((resolve, reject) => {
                if(!ref) {
                    if(!id) id = context.state.user && context.state.user.pages[0] ? context.state.user.pages[0].id : null;
                    if(id) ref = doc(context.state.db.pages, id);
                }

                if(!ref) resolve(null);

                getDoc(ref).then(pss => {
                    if(pss.exists()) {
                        var page = pss.data();
                        console.log({page});
                        context.commit("SET_PAGE", page);
                        resolve(page);
                    } else {
                        reject(new Error("Page " + ref.id + " not found"));
                    }
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
            })
        },
        savePage: function(context, { ref, id, data }) {
            return new Promise (( resolve, reject ) => {
                if(!ref && id) ref = doc(context.state.db.pages, id);
                if(!data) return null;
                notyf.dismissAll();
                data.updated_at = serverTimestamp();
                setDoc(ref, data).then(() => {
                    context.commit("SET_PAGE", data);
                    //toast.success("saved!");
                    notyf.success("saved");
                    resolve(data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })

            })
        },
        notify: function(context, {style, text}) {
            notyf.dismissAll()
            switch(style) {
                case "success":
                    notyf.success(text);
                    break;
                
                case "error":
                    notyf.error(text);
                    break;
            }
        },
        updateUser: function(context, { data }) {
            return new Promise((resolve, reject) => {

                if(!data) reject(new Error("no id or data specified"));

                var userRef = context.state.userRef;

                notyf.dismissAll();

                updateDoc(userRef, data)
                .then(() => {
                    console.log(userRef.id, "updated");
                    notyf.success("user updated");
                    resolve(true)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
            })
        },
        updatePageHandle: function(context, { page }) {
            return new Promise((resolve, reject) => {
                if(!page) reject(new Error("no page specified"));

                var userRef = context.state.userRef;
                var user = context.state.user;

                var pageIndex = findIndex(user.pages, p => { return p.id === page.id; });
                

                if(pageIndex > -1) {
                    user.pages[pageIndex].handle = page.handle;

                    updateDoc(userRef, { pages: user.pages })
                    .then(() => {
                        console.log(`Page ${page.handle} updatef for user ${user.id}`);
                        notyf.success("Page handle updated!");
                        resolve(true);
                    })
                    .catch(err => {
                        console.error(err);
                        reject(err);
                    })

                } else {
                    reject(new Error(`Page ${page.id} doesn't belong to user ${user.id}`));
                }
            })
        }
    }
}